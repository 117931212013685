button {
  white-space: nowrap;
}

.hiddenRow {
  padding: none !important;
  border: none !important;
}

.hiddenRow > td {
  padding: 0 !important;
}
